$( document ).ready(function() {

    updateBasketOnload();

    Handlebars.registerHelper('translate', function(key) {
        return window.app.translations[key];
    });

    quantityCheck();

    // Update basket
    $(document).on('click', '.update-basket', function (e) {
        e.stopPropagation();
        var container = $(this);
        var productid = $(this).data("productid");

        if ($(this).is("[type='checkbox']")) {
            var quantity = parseInt($(this).children("input[type=checkbox]").val());
            if (isNaN(quantity) || quantity === undefined) {
                if($(this).is(":checked")){
                    quantity = 0;
                }else{ 
                    quantity = -1;
                }               
                //quantity = parseInt($(this).val()-1);
            }
        } else {
            var quantity = parseInt($(this).siblings("input[type=text]").val());
        }

        if ($(this).data("action") == "remove") {
            if (quantity >= 0) {
                sendData('remove', productid, quantity, container, true);
                if (quantity == 1 && $(".product_overview").length) {
                    $('tr[data-productid="' + productid + '"]').fadeOut("slow", function () {
                        $('tr[data-productid="' + productid + '"]').remove();
                    });
                }
            }
        } else {          
            sendData('add', productid, quantity, container);
        }
    });


    function priceFormat(value){
        value = parseFloat(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        //output example: 8.264,46
        return value;
    }

    // ajax requests
    function sendData(action, productid, quantity, container, redirectTo = false) {
        // check if ajax call already firing
        if($.active > 0){
            return false;
        }

        // get ajax url based on action
        var url = Routing.generate('basket_ajax_update_basket');

        // calc new quantity
        if (action === 'remove') {
            quantity--;
            if (quantity < 0) {
                quantity = 0;
            }
        }

        if (action === 'add') {
           quantity++;
        } 

        // set post data in json object
        var data = formatData(action, productid, quantity, redirectTo);

        // send ajax request
        $.ajax({
            type: "POST",
            url: url,
            data: data,
            beforeSend: function () {
                container.siblings(".loader").show();
                $('.amount-box').addClass("loading");
            },              
            success: function (data) {
                updateQuantity(quantity, container);
                updateCartIcon();
                updateFloatingBasket(JSON.parse(data.basket.products), true);

                container.siblings(".loader").hide();
                $('.amount-box').removeClass("loading");
             
                if($(".product_overview").length) {                    
                    var totalFalse =  priceFormat(data.basket.totalFalse);
                    var vatAmount = priceFormat(data.basket.vatAmount);
                    var totalTrue = priceFormat(data.basket.totalTrue);
                    $('.footer_basket_total_excl').html("&euro;&nbsp;"+totalFalse);
                    $('.footer_basket_total_vat').html("&euro;&nbsp;"+vatAmount);
                    $('.footer_basket_total_inc').html("&euro;&nbsp;"+totalTrue);
                    $.each( data.basket.vatAmounts, function( key, value ) {
                        var vat = priceFormat(data.basket.vatAmounts[key].amount);
                        $('.vat-'+key.replace(".", "")).html("&euro;&nbsp;"+vat)
                    });

                    if(data.basket.totalBasketElements == 0){
                        window.location.href = data.basket.redirectTo;
                    }
                }
                quantityCheck(); 
            }
        });
    }

    // update quantity in DOM
    function updateQuantity(quantity, container) {
        container.siblings("input[type=text]").val(quantity);
    }    

      // format product data to conform to basketbundle
    function formatData(action, productid, newQuantity, redirectTo) {
        var data = {
            sonata_basket_basket:
                {
                    productId: productid,
                    quantity: newQuantity
                }
        };
        data.service = $('.service-products').data('service');
        return data;
    }

    function quantityCheck(){
        var collectionIds = $(".service-product .product-item").map(function() {
            return $(this).find('.amount-box input[type="text"], .amount-box input[type="checkbox"]').data("collection");
        }).get();
        $.each(unique(collectionIds), function(key, value){
            quantity = 0;
            $('[data-collection="'+value+'"]').each(function () {
                if (!$(this).is('[type="checkbox"]') || $(this).is(':checked'))
                    quantity += parseFloat($(this).val());

            });
            if ($('[data-collection="' + value + '"]').data('maxQuantity') != '' && quantity >= $('[data-collection="' + value + '"]').data('maxQuantity')) {
                $('[data-collection="' + value + '"][type="checkbox"]:not(:checked)').attr('disabled', true);
                $('[data-collection="' + value + '"]:not(:checked)').closest('.amount-box').addClass("disabled");
                $('[data-collection="' + value + '"]').next().addClass("disabled");
            } else {
                $('[data-collection="' + value + '"][type="checkbox"]').removeAttr('disabled', true);
                $('[data-collection="' + value + '"]').closest('.amount-box').removeClass("disabled");
                $('[data-collection="' + value + '"]').next().removeClass("disabled");
            }
        });
    }

    function unique(list) {
        var result = [];
        $.each(list, function(i, e) {
            if ($.inArray(e, result) == -1) result.push(e);
        });
        return result;
    }
});

 // update cart counter main menu
 function updateCartIcon() {
    var counter = 0;
    $( ".service-product-info" ).each(function( index )
    {
        counter += parseInt($(this).val());
    });
    counter  += $(".checkcontainer .update-basket:checked").length;


    $(".basketCounter").html(counter);
}

var basketTouched = false
function updateFloatingBasket(products, popout = false) {
   
    $('.menu-basket-content').children('.basket-item').each(function(i, e){
        $(e).remove();
    });
    let that = this;
    $.each(products, function( index, value ) {
        $( that["app"]["templates"]["floating-basket-item"](value) ).insertBefore('.menu-basket-content .checkout');
    });
    if (popout) {
        $(".menu-basket").trigger('mouseenter');
        $(".menu-basket").bind( "mouseenter", basketHoverEvent);
        setTimeout(function(){
            $(".menu-basket").unbind( "mouseenter", basketHoverEvent);
            if(!basketTouched){
                $(".menu-basket").trigger('mouseleave');
            }           
            basketTouched = false;
        }, 5000);
    }
}
 function basketHoverEvent(event){
    basketTouched = true;
 }

 function updateBasketOnload(){
    updateCartIcon();
    var url = Routing.generate('basket_ajax_get');
    $.ajax({
        type: "GET",
        url: url   
    }).done(function(data){
        if(data.status == true){            
            updateFloatingBasket(JSON.parse(data.basket.products), true);
        }
    });
 }
