$( document ).ready(function(){
    $('.modal').modal();
    function setStep(step){
        step = (step-1);
        var i = 0;
        $('.step-nav li').each(function( index ) {
            if(i != step){
                $( this ).removeClass('active');
            } else {
                $( this ).addClass('active');
                $('.stepheader').html($( this ).find('span').html());
            }
            i++;
        });
    }

    $( ".cat-checkbox" ).on( "click", function() {
        $(".step:nth-child(3)").fadeOut();
        var headcat = $(this).data('catid');
        var headcatselector = $("[data-head='"+headcat+"']");
        $(".vid-"+headcat).removeClass("hide");
        $(".video-logo-container").removeClass("hide");
        $(".video-title-container").removeClass("hide");
        $(".modal").css('display',"none");
        if($(this).parent().parent().parent().hasClass('headcat')){
            $(".headcat").hide();
            headcatselector.show().removeClass("l3").addClass("l12");
            setStep(2);
        } else {
            $("[data-parent='"+$(this).parent().parent().parent().data('parent')+"']").hide();
            var catselector = $("[data-catid='"+$(this).parent().parent().parent().data('catid')+"']");
            catselector.show().removeClass("l3").addClass("l12");
        }
        $(this).parent().parent().find('.edit').first().show();
        $(this).parent().hide();
        $("[data-parent='"+headcat+"']").show();
        if($(this).parent().parent().parent().hasClass('lastcat')){
            setStep(3);
            $('.categoryId').attr('value', headcat);
            $(".step:nth-child(3)").fadeIn();
        }
    });

    $( ".reload_repair" ).on( "click", function() {
        setStep(1);
        var catid = $(this).data('catid');
        $(".step:nth-child(2)").fadeIn();
        $(".last-step").fadeOut();
        $("#app_user_repair_request_message").val("");
        $("#app_user_repair_request_file_file").val(null);
        $(".vich-file a").css("visibility","hidden");
        $(".vid-"+catid).addClass("hide");
    });

    $( ".edit span" ).on( "click", function() {
        $(".step:nth-child(3)").fadeOut();
        var catid = $(this).data('catid');
        var catparentselector = $("[data-parent='"+catid+"']");
        var catselector = $("[data-catid='"+catid+"']");
        if($("[data-head='"+catid+"']").length){
            $("[data-parent]").hide();
            $("[data-parent] div").show();
            $("[data-parent] .edit").hide();
            $("[data-parent] div input").prop('checked', false);
            $("[data-head]").show();
            $("[data-head] div").show();
            $(".edit").hide();
            $("[data-head] div input").prop('checked', false);
            catparentselector.removeClass("l12").addClass("l3");
            catselector.removeClass("l12").addClass("l3");
            $( "div[class*='vid-']" ).addClass('hide');
            setStep(1);
        } else {
            catselector.show();
            $("[data-catid='"+catid+"'] div").show();
            $("[data-catid='"+catid+"'] .edit").hide();
            $("[data-catid='"+catid+"'] div input").prop('checked', false);
            var parentid = $("[data-catid='"+catid+"']").data('parent');
            var currentlevel = $(this).data('level');
            var levelselector = $("[data-level='"+currentlevel+"']");
            levelselector.removeClass("l12").addClass("l3");
            $('.step ul').each(function( index ) {
                if($(this).data('level') > currentlevel){
                    $(this).hide();
                    $(this).removeClass("l12");
                    $(this).addClass("l3");
                    $(this).find("div").show();
                    $(this).find(".edit").hide();
                    $(this).find("div input").prop('checked', false);
                }
            });
            $(".vid-"+catid).addClass("hide");
            $("[data-parent='"+parentid+"']").show();
            setStep(2);
        }
    });

    if($('.reques_form_complete').length){
        $(".step:nth-child(2)").fadeOut();
        $(".step:nth-child(3)").fadeOut();
        $(".step:nth-child(4)").fadeIn();
        setStep(4);
    } else {
        const selectedCat = $('#repairform:not([data-category=false])').first().data('category');
        const categories = []

        if (selectedCat !== undefined) {
            addCategories(selectedCat);
        }

        function addCategories(catId) {
            categories.unshift(catId);
            var checkbox = $('ul[data-catid="'+catId+'"]').first().data('parent');
            if (checkbox !== undefined) {
                addCategories(checkbox)
            }
        }

        for (const catId of categories) {
            $('.cat-checkbox[value="'+catId+'"]').first().trigger('click');
        }
    }
});
