this["app"] = this["app"] || {};
this["app"]["templates"] = this["app"]["templates"] || {};
this["app"]["templates"]["floating-basket-item"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " no-photo";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<img width=\"180\" height=\"125\" src=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.media : stack1)) != null ? stack1.formats : stack1)) != null ? stack1.preview : stack1), depth0))
    + "\" />";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <span >€</span>\n        "
    + container.escapeExpression((helpers.numberFormat || (depth0 && depth0.numberFormat) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.price : depth0),{"name":"numberFormat","hash":{"decimalSep":",","thousandsSep":".","decimalLength":"2"},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"menu-item basket-item"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.media : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" role=\"menu-item\">\n  <a class=\"row\" href=\"#\">\n    "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.product : depth0)) != null ? stack1.media : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n  </a>\n  <span class=\"quantity\">\n    "
    + alias4(((helper = (helper = helpers.quantity || (depth0 != null ? depth0.quantity : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quantity","hash":{},"data":data}) : helper)))
    + " × \n    <span class=\"amount\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.price : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </span>\n  </span>\n</li> ";
},"useData":true});