
/**
 *
 * Validate a http url
 *
 * @param  string   str     The url to validate
 * @return boolean          Rerurns true if the url validates
 *
 */
function aveq_validate_url(str) {

    var pattern = new RegExp('^(https?:\\/\\/)?'+           // Protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+  // Domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+                        // Or ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+                    // Port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+                           // Querystring
      '(\\#[-a-z\\d_]*)?$','i');                            // Fragment locator

      return pattern.test(str);

}
