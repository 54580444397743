/**
 * Main application javascript file
 */

$(document).ready(function () {
    //initialize scrollspy
    $('.scrollspy').scrollSpy();

    $('.product-modal').modal();

    Materialize.updateTextFields();

    var count = 0;
    var interval = setInterval(function () {
        count++;
        try {
            $('input[type=password]:-webkit-autofill').siblings('label').addClass('active');
            if (count > 500 || $('input:-webkit-autofill').length == 0) {
                clearInterval(interval);
            }
        } catch (e) {
            clearInterval(interval);
        }
    }, 10);

    // Initiate homepage homepage sellingpoints carousel
    $('.carousel-sellingpoints').carousel({
        indicators: true,
        fullWidth: true
    });

    // sellingpoints carousel navigation arrows
    $('.carousel-sellingpoints .icon-angle-left').on('click', function () {
        $('.carousel-sellingpoints').carousel('prev');
    });

    $('.carousel-sellingpoints .icon-angle-right').on('click', function () {
        $('.carousel-sellingpoints').carousel('next');
    });




    // Initiate homepage homepage sellingpoints carousel
    $('.carousel-testimonials').carousel({
        indicators: false,
        fullWidth: true
    });

    // testimonials carousel navigation arrows
    $('.testimonials-carousel .angle-left').on('click', function () {
        $('.carousel-testimonials').carousel('prev');
    });

    $('.testimonials-carousel .angle-right').on('click', function () {
        $('.carousel-testimonials').carousel('next');
    });



    /**
     * Enable mobile menu
     */
    var menuIsOpen, basketIsOepn = false;
    $('#mobile-collapse').sideNav({
        menuWidth: 300,             // Default is 300
        edge: 'left',          // Choose the horizontal origin
        closeOnClick: true,            // Closes side-nav on <a> clicks, useful for Angular/Meteor
        draggable: true,            // Choose whether you can drag to open on touch screens,
        onOpen: function (el) {
            menuIsOpen = true
            if (basketIsOepn) {
                $('#basket-collapse').sideNav('hide');
            }
        },
        onClose: function (el) {
            menuIsOpen = false
        },
    });


    $('#basket-collapse').sideNav({
        menuWidth: 300,             // Default is 300
        edge: 'right',          // Choose the horizontal origin
        closeOnClick: true,            // Closes side-nav on <a> clicks, useful for Angular/Meteor
        draggable: true,            // Choose whether you can drag to open on touch screens,
        onOpen: function (el) {
            basketIsOepn = true
            if (menuIsOpen) {
                $('#mobile-collapse').sideNav('hide');
            }
        },
        onClose: function (el) {
            basketIsOepn = false
        },
    });

    $('.js-close-mobile-menu').on('click', function () {
        $('#mobile-collapse').sideNav('hide');
    });

    $('.js-close-basket-menu').on('click', function () {
        $('#basket-collapse').sideNav('hide');
    });


    $('body').on('click', function () {
        if ($('#mobile-notification-menu .notification-container').is(":visible")) {
            $('#mobile-notification-menu').hide();
            $('#mobile-notification-menu .notification-container').hide();
        } else {
        }
    });
    $('#notification-collapse').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        if ($('#mobile-notification-menu .notification-container').is(":visible")) {
            $('#mobile-notification-menu').hide();
            $('#mobile-notification-menu .notification-container').hide();
        } else {
            $('#mobile-notification-menu').show();
            $('#mobile-notification-menu .notification-container').show();
        }
    });

    // Menu responsive width calculator
    function calculateMenuCollapseSize(menuWidth) {
        // if (menuWidth > 992 && menuWidth < 1053) {
        //     menuWidth = 1055;
        // }
        var screenWidth = $(window).width();
        var logoWidth = $(".brand-logo").width();
        var spacerWidth = 50; //width between logo and menu
        var totalHeaderWidth = (logoWidth + menuWidth + spacerWidth);

        var el = $(".site-navigation ul.hide-on-med-and-down");
        if (totalHeaderWidth > screenWidth) {
            el.addClass("hide");
            $(".side-nav").addClass("mobile_menu_active");
            $(".main-menu-button-collapse").addClass("show-on-medium-and-up");
        } else {
            el.removeClass("hide");
            $(".side-nav").removeClass("mobile_menu_active");
            $(".main-menu-button-collapse").removeClass("show-on-medium-and-up");
        }

        // below normal screen size (992px materialize) default back and remove classes
        if (screenWidth < 975) {
            el.removeClass("hide");
            $(".main-menu-button-collapse").removeClass("show-on-medium-and-up");
        }
    }

    // get width of main menu
    var menuWidth = $(".site-navigation ul.right").width();

    calculateMenuCollapseSize(menuWidth);

    $(window).resize(function () {
        calculateMenuCollapseSize(menuWidth);
    });
    // end menu responsive width calculator

    function scrolltotop(offsetTopPicker) {
        if ($(window).width() <= 900) { offsetTopPicker = (offsetTopPicker * 2); }
        $('html, body').animate({
            scrollTop: (offsetTopPicker)
        }, 10);
    }

    /**
     * Initialize Form elements
     */

    /*
     * datepicker language selection, default values are english
     * more languages available at https://github.com/amsul/pickadate.js/tree/master/lib/translations
     */

    if (locale == 'nl') {

        $.extend($.fn.pickadate.defaults, {
            monthsFull: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
            monthsShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
            weekdaysFull: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
            weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
            weekdaysLetter: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
            today: 'vandaag',
            clear: 'wissen',
            close: 'OK',
            firstDay: 1,
            format: 'dddd d mmmm yyyy',
            formatSubmit: 'yyyy/mm/dd'
        });
    }

    $('.datepicker, input[type="date"]').pickadate({
        selectMonths: true, // Creates a dropdown to control month
        firstDay: 1,
        selectYears: 200, // Creates a dropdown of 15 years to control year,
        closeOnSelect: false, // Close upon selecting a date,
        format: 'dd-mm-yyyy',
        formatSubmit: 'dd-mm-yyyy',
        onOpen: function () {
            $('body').addClass('pickadate-open');
            if (this.trigger().$node.hasClass("birthdatepicker") && this.get("value") == '') {
                //if empty & type is birthdate then start 30 years back
                date = new Date();
                date.setYear(date.getYear() - 30);
                this.set("select", date);
            }
        },
        onClose: function () {
            $('body').removeClass('pickadate-open');
        }
    });

    $(document).ready(function () {
        $('.imageGallery .image').materialbox();
    });


    // $('.datepicker, input[type="date"]').pickadate({
    //     selectMonths: true,
    //     firstDay: 1,
    //     selectYears: 200,
    //     format: 'dd-mm-yyyy',
    //     formatSubmit: 'dd-mm-yyyy',
    //     container: 'html',
    //     closeOnSelect: false,
    //     onClose: function() {
    //         var offsetTopPicker = ($('#nav').hasClass( "shrink" ) ? ($('.site-navigation').height()*2) : $('.site-navigation').height()) + 10;
    //         scrolltotop(offsetTopPicker);
    //     },
    //     onOpen: function() {
    //         var offsetTopPicker = ($('#nav').hasClass( "shrink" ) ? ($('.site-navigation').height()*2) : $('.site-navigation').height()) + 10;
    //         scrolltotop(offsetTopPicker);
    //     },
    //     onSet: function() {
    //         var offsetTopPicker = ($('#nav').hasClass( "shrink" ) ? ($('.site-navigation').height()*2) : $('.site-navigation').height()) + 10;
    //         scrolltotop(offsetTopPicker);
    //     },
    //     onRender: function() {
    //         var offsetTopPicker = ($('#nav').hasClass( "shrink" ) ? ($('.site-navigation').height()*2) : $('.site-navigation').height()) + 10;
    //         scrolltotop(offsetTopPicker);
    //     }
    // });

    $('select').not('.select2').material_select();

    //select2
    $('.select2').select2({
        language: locale
    });



    $form = $("#subaccount-form");
    if ($("#subaccount-toggle input").is(":checked")) {
        $form.removeClass("hidden");
    }
    //Registration form subaccount toggle
    $("#subaccount-toggle input").click(function (e) {
        if ($form.hasClass("hidden")) {
            $form.removeClass("hidden");
        } else {
            $form.addClass("hidden");
        }
    });


    /**
     * Add click event on whole cards using the link in the card
     */
    $('.js-block-link').on('click', function () {

        var link = $(this).find('a').attr('href');

        if (link) {
            if (aveq_validate_url(link)) {
                window.location.href = link;
            } else {
                console.log('No link');
            }
        } else {
            console.log('No link');
        }
        return false;
    });



    /**
     * Apply imgLiquid to needed elements
     */
    $(".imgLiquidFill").imgLiquid();

    /**
     * Enable scrollto on .js-jumpto elements
     */
    $('.js-jumpto').on('click', function () {

        var href = $(this).attr('href');
        var target = $('body').find(href).length

        // Check if the target is a valid target (#target-id)
        if (href.charAt(0) == '#') {

            // Check if the target ID exists in the dom
            if (target >= 1) {
                $('html, body').animate({ scrollTop: $(href).offset().top - 64 }, 500);
            } else {
                console.error('No target found in the dom.');
            }

        } else {
            console.error('Link target is invalid');
        }

    });

    /**
     * Show flash modal & toast messages
     */
    setTimeout(function () {
        $("#flash-modals .modal").each(function () {
            $(this).modal();
            $(this).modal('open');
        });

        $("#flash-toasts .toast").each(function () {
            Materialize.toast($(this).html(), 4000)
        });
    }, 500);


    /* Toggle presence table */
    $('.togglePresence').on('click', function (e) {
        e.preventDefault();
        $table = $('.presence');
        if ($table.hasClass("hide")) {
            $table.removeClass("hide");
        } else {
            $table.addClass("hide");
        }
    })


    //toggle profile menu
    $(".profile-menu .toggle").on("click", function () {
        if ($(this).parent().hasClass("open")) {
            $(this).parent().removeClass("open");
        } else {
            $(this).parent().addClass("open")
        }
    });

    $('.collapsible').collapsible();

    //menu hover
    $("nav ul.right>li").hover(function () {
        $(this).find("ul").stop().fadeIn();
    }, function () {
        $(this).find("ul").stop().fadeOut();
    });


    // hover image for service managers
    $(".person-image").hover(
        function () {
            var hoverimage = $(this).data('hoverimage');
            if (hoverimage) {
                $(this).css("background-image", "url(" + hoverimage + ")");
            }
        }, function () {
            var normalimage = $(this).data('normalimage');
            if (normalimage) {
                $(this).css("background-image", "url(" + normalimage + ")");
            }
        }
    );

    // enable svg4everybody failbacks
    svg4everybody();


    //cancel booking
    $(".cancelBooking").on("click", function (e) {
        e.preventDefault();
        $('#cancelBooking').attr("data-link", $(this).attr("href"));
        $('#cancelBooking').modal();
        $('#cancelBooking').modal('open');
    });

    $("#cancel_YesBtn").click(function () {
        location.href = $('#cancelBooking').attr("data-link");
    });


    //remove address
    $(".removeAddress").on("click", function (e) {
        e.preventDefault();
        $('#removeAddress').attr("data-link", $(this).attr("href"));
        $('#removeAddress').modal();
        $('#removeAddress').modal('open');
    });

    $("#remove_address_Yes").click(function () {
        location.href = $('#removeAddress').attr("data-link");
    });

    //update repair status
    $(".updateRepairStatus").on("click", function (e) {
        location.href = Routing.generate('repair_request_detail', { id: $(this).attr("data-repairid") });
    });

    $("#residentFinishButton").on("click", function (e) {
        e.preventDefault();
        let $changeRepairStatusModal = $('#changeRepairStatusModal');
        $changeRepairStatusModal.modal();
        $changeRepairStatusModal.modal('open');
    });
    $("#updateStatus_YesBtn").click(function (e) {
        e.preventDefault();
        $('#changeRepairStatusModal').modal('close');
        $.ajax({
            type: "POST",
            url: Routing.generate('repair_request_status_update', { id: $('#changeRepairStatusModal').data("id") }),
            data: 'repair_request_status[status]='+$('#changeRepairStatusModal').data("status")+'&repair_request_status[_token]='+$('#changeRepairStatusModal').data("token")
        }).done(function (data) {
            location.reload();
        });
    });



    //floorservice
    var selectedType = "";
    function setFloorStep(step) {
        $(".floorservice .step").removeClass('active');
        $(".floorservice .nav ul li").removeClass('active');
        $(".floorservice .nav ul #step-" + step).addClass('active');
        $("#step-" + step + "-content").addClass('active').hide().fadeIn();

        if (step == 5) {
            var residenceType = $('#residence-type').val();

            if (residenceType != "" && residenceType != 0) {
                var price = prices[residenceType][selectedType];

                if ($('input[name="plint"]:checked').val() == "High plinth" || $('input[name="plint"]:checked').val() == "Hoge plint") {
                    price = parseInt(price) + parseInt(plintprices[residenceType]);
                }

                price = Number(price).toLocaleString();

                $('.endPrice').html(price);
                $('#endPrice').val(price);
            }
        }
    }

    $(".floorservice .nav li").click(function () {
        var step = $(this).attr('id').replace("step-", "");
        if (step != 6) {
            if (step == 5 && selectedType == "") {
                return false;
            }
            setFloorStep(step);
        }
    });


    $(".floorservice .step label").click(function () {
        var step = $(".floorservice .nav ul .active").attr('id').replace("step-", "");
        var html = '';

        if (step == 1) {
            $(".step .colors li").hide();
            $(".step .colors ." + $("#" + $(this).attr('for')).data("type")).show();
            selectedType = $("#" + $(this).attr('for')).data("type");
            $(".floorservice .nav ul #step-2 .selected-option").html("");
            $(".floorservice .nav ul #step-2 .edit").addClass("hide");
        }

        $(".floorservice .nav ul .active .edit").removeClass('hide');
        if ($("#" + $(this).attr('for')).data('text') != "") {
            html = html + "<span>" + $("#" + $(this).attr('for')).data('text') + "</span>";
        }
        if ($("#" + $(this).attr('for')).data('image') != "") {

            if (step != 1) {
                html = html + '<img src="' + $("#" + $(this).attr('for')).data('image') + '" alt="Titel">';
            } else {
                html = html + '<svg><use xlink:href="' + $("#" + $(this).attr('for')).data('image') + '"></use></svg>';
            }
        }

        if (step == 4) {
            $(".floorservice .nav ul #step-" + step + " .selected-option ." + $("#" + $(this).attr('for')).attr('name')).html(html);
        } else {
            $(".floorservice .nav ul #step-" + step + " .selected-option").html(html);
            step = (parseInt(step) + 1);
            setFloorStep(step);
        }


    });

    $(".floorservice .step #nextstep_floor_service").click(function () {
        if (selectedType == "") {
            return false;
        }
        setFloorStep(5);
    });

    $('a').each(function () {
        if ($(this)[0].hostname != window.location.hostname && $(this)[0].href != "" && $(this)[0].href != "#" && $(this)[0].href.indexOf('mailto:') == -1 && $(this)[0].href.indexOf('tel:') == -1) {
            $(this).addClass('ext-url').addClass('e-url');
            $(this).attr('target', '_blank');
        }
    });
    // end floorservice

    Handlebars.registerHelper('numberFormat', function (value, options) {
        // Helper parameters
        var dl = options.hash['decimalLength'] || 2;
        var ts = options.hash['thousandsSep'] || ',';
        var ds = options.hash['decimalSep'] || '.';

        // Parse to float
        var value = parseFloat(value);

        // The regex
        var re = '\\d(?=(\\d{3})+' + (dl > 0 ? '\\D' : '$') + ')';

        // Formats the number with the decimals
        var num = value.toFixed(Math.max(0, ~~dl));

        // Returns the formatted number
        return (ds ? num.replace('.', ds) : num).replace(new RegExp(re, 'g'), '$&' + ts);
    });

    if ($('.service-modal').length) {
        $('.service-modal').modal();
        $('.service-modal').modal('open');
    }

    $('.notification-icon').on('click', function (e) {
        e.preventDefault();
        var url = Routing.generate('notifications_read_all');
        $.ajax({
            type: "GET",
            url: url
        }).done(function (data) {
            $('.notificationCounter').remove();
            $('.notification').removeClass('unread');
        });
    });
});

//Confirm modals
// function showModal(but, modal){

//     $('#' + modal + '_YesBtn').click(function(){ $('#' + modal).closeModal(); document.location = but.href; });
// }

//shrink header on scroll.
$(document).on("scroll", function () {
    if (!$("body").hasClass('app_view')) {
        if ($(document).scrollTop() > 74) {
            $("body").addClass("shrink");
        }
        else {
            $("body").removeClass("shrink");
        }
    }

});
$(document).on("click", ".select-wrapper", function (event) { event.stopPropagation(); });
$(document).on("click", ".input-field", function (event) { event.stopPropagation(); });
