$(document).ready(function () {

    $('.serviceManagerInformation .owl-carousel').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        autoplay: true,
        autoplayTimeout: 10000,
        autoplayHoverPause: false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        responsive: {
            0: {
                items: 1
            }
        }
    });

    if ($('#timeline').length > 0) {
        $(window).scroll(function () {
            if ($(window).scrollTop() + $(window).height() > ($(document).height() * 0.75)) {
                fetchMessages();
            }
        });
        var loadingMessages = false;
        function fetchMessages() {
            if (loadingMessages === false) {
                loadingMessages = true;
                $.ajax({
                    type: "POST",
                    url: Routing.generate('timeline_messages', ''),
                    data: { offset: $('#timeline').data("number-of-messages") },
                })
                    .done(function (data) {
                        if (data.message.length > 0) {
                            $('#messageBoard').append(data.message);
                            $('.timelineImage').materialbox();
                            $('.singleImage').materialbox();
                            $('#timeline').data("number-of-messages", JSON.parse(data.offset));
                            addReplySubmitFunction();
                            initMoreLess();
                            loadingMessages = false;
                        } else {
                            $('#noMoreMessages').show();
                        }
                    });
            }
        }
    }
    if ($('#timelineSegment').length > 0) {
        addReplySubmitFunction();
    }

    $('#timelineSegment').on("focus", 'form[name="timeline_message"] .input-field > input', function () {
        $(".sendButton,.menuIcon,.replyForm .input-field,.replyForm .replyButtons").removeClass('active');
        $(this).parent().parent().find(".sendButton").addClass('active');
        $(this).parent().parent().find(".menuIcon").addClass('active');
        $(this).parent().parent().find(".input-field").addClass('active');
        $(this).parent().parent().find(".replyButtons").addClass('active');


    });

    $('#timelineSegment').on("input", 'form[name="timeline_message"] .input-field > input', function () {
        if ($(this).val().length >= 1) {
            $(this).parent().parent().find(".sendButton").addClass('bold');
        } else {
            $(this).parent().parent().find(".sendButton").removeClass('bold');
        }
    });

    $('#timelineSegment').on("focusout", 'form[name="timeline_message"] .input-field > input', function () {
        if ($('form[name="timeline_message"] .input-field > input').val().length < 1) {
            $(".sendButton,.menuIcon,.replyForm .input-field,.replyForm .replyButtons").removeClass('active');
        }
    });

    $('.timelineContainer .formContainer form[name=timeline_message] textarea').on("focus", function () {
        $(this).addClass('minHeight');
    });
    $('.timelineContainer .formContainer form[name=timeline_message] textarea').on("focusout", function () {
        if ($('.timelineContainer .formContainer form[name=timeline_message] textarea').val().length < 1) {
            $(this).removeClass('minHeight');
        }
    });

    $('#timelineSegment').on("click", '.replyButtons .menuIcon', function () {
        $('#' + $(this).data('form') + ' .mediaUploadSection').show()
    });

    $('#timelineSegment').on("submit", 'form[name="timeline_message"]', function () {
        $(this).find('.loadIcon').show();
    });

    $('#modalConfirmMessage').modal({
        dismissible: false,
    });

    var hasConfirmed = false;

    if ($('#modalConfirmMessage').length) {
        $('#timeLineMessage').on("submit", function(e) {
            if (!hasConfirmed) {
                e.preventDefault();
                $('#modalConfirmMessage').modal('open');
                $('#modalConfirmMessage .messageBody div').text(
                    $('[name="timeline_message[message]"]').val()
                );
            }
        });
    }

    $('[data-preview-confirm]').on('click', function (e) {
        hasConfirmed = true;
        $('#timeLineMessage').submit();
    });

    $('[data-preview-edit]').on('click', function (e) {
        $('#timeLineMessage').find('.loadIcon').hide();
    });

    $('#timelineSegment').on("click", '.happy', function (e) {
        var action = 'add';
        if ($(this).hasClass('happied')) {
            action = 'remove'
        }
        var button = $(this);

        if (action === 'remove') {
            button.removeClass('happied');
        } else {
            button.addClass('happied');
        }
        var that = this;
        $.ajax({
            type: "POST",
            url: Routing.generate('timeline_happy', { id: $(this).data("item"), action: action }),
        })
            .done(function (data) {
                if (data.success && typeof data.happies !== 'undefined' && typeof data.count !== 'undefined') {
                    var intCount = parseInt(data.count);
                    if (intCount > 1) {
                        var template = $('#timelineSegment').data("happy-message");
                        var renderedTemplate = template.replace('%fullname%', data.happies[0]).replace('%totalHappies%', (intCount - 1)).replace('%happyPeople%', data.happies.join(', '));
                        $(that).siblings('.happies').html(renderedTemplate);
                    } else if (intCount === 1) {
                        var singleTemplate = $('#timelineSegment').data("single-happy-message");
                        $(that).siblings('.happies').html(singleTemplate.replace('%fullname%', data.happies[0]));
                    } else {
                        $(that).siblings('.happies').html('');
                    }
                }

            });

    });
    initMoreLess();

    $("#timeline_message_important").change(function () {
        if (this.checked) {
            $("p.alert").not(".app-preview").hide();
            $(".app-preview").show();
        } else {
            $("p.alert.app-preview").hide();
            $(".app-preview").hide();
        }
    });

    if( $(".app-preview").length > 0 ) {
        $("#timeline_message_message").keyup(function () {
            let string = $(this).val().trim();
            if (string.length > 70) {
                string = string.substr(0, 70) + '...'
            }
            $(".app-preview p").html(string);
        });
    }

    let isLoading = false;
    $('#messageBoard').on("click", ".additionalResponses", (event) => {
        event.preventDefault();
        if (isLoading || event.target.href === '#') return;
        isLoading = true;
        $.ajax({
            type: 'GET',
            url: event.target.href,
            contentType: 'application/json'
        }).done((data) => {
            if (data.nextLink === null) {
                $(event.target).hide();
            } else {
                event.target.href = data.nextLink;
            }
            const id = event.target.getAttribute('data-message-id');
            $('#responses_' + id).append(data.html);
            $('.timelineImage').materialbox();
            $('.singleImage').materialbox();
            isLoading = false;
        });
    })
});

var loadingReply = false;
function addReplySubmitFunction() {
    $('[id*=replyForm_]').submit(function (e) {
        e.preventDefault();
        if (loadingReply === false) {
            loadingReply = true;
            var id = $(this).parents('[id*="reply_"]').data('messageid');
            var replyForm = new FormData(this);
            $.ajax({
                type: "POST",
                url: Routing.generate('timeline_reply', { target: id }),
                data: replyForm,
                processData: false,
                contentType: false
            })
                .done(function (data) {
                    if (data.success) {
                        $('#responses_' + id).prepend(data.form);
                        $('#replyForm_' + id + ' input[type="text"]').val('');
                        $('#replyForm_' + id + ' input[type="file"]').val('');
                        $('#reply_' + id + ' .formContainer ul').remove();
                        $('.timelineImage').materialbox();
                    } else {
                        $('#reply_' + id + ' .formContainer').replaceWith(data.form);
                        $('#reply_' + id).find(".sendButton").addClass('active');
                        $('#reply_' + id).find(".menuIcon").addClass('active');
                        $('#reply_' + id).find(".input-field").addClass('active');
                        $('#reply_' + id).find(".replyButtons").addClass('active');
                        $('#replyForm_' + id + ' .mediaUploadSection').show();
                        addReplySubmitFunction(id);
                    }
                    $('#reply_' + id + ' .replyButtons .loadIcon').hide();
                    loadingReply = false;
                })
                .fail(function (data) {
                    $('#replyForm_' + id + ' button[type="submit"').attr('disabled', 'disabled');
                    $('#error_replyForm_' + id).show();
                    $('#reply_' + id + ' .replyButtons .loadIcon').hide();
                    loadingReply = false;
                });
        }
    });
}
function initMoreLess() {
    var readMoreLess = $('#timeline [data-mrc]:not(".mrc")');
    readMoreLess.moreContent({
        speed: 800,
        shadow: true,
        height: 105,
        textClose: $('#timeline').data("close-message"),
        textOpen: $('#timeline').data("open-message"),
        tpl: {
            btn: '<span class="mrc-btn readMoreLess"></span>'
        }
    });
}
