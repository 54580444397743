$(document).ready(function(){
  
    $('.card-event .card-action [data-action="subscribe"]').click(function(){
        var eventId = $(this).data("event");
        if($(this).hasClass("checked")){  console.log($(this).data('event-title'));      
            $('#cancelRegistration').attr("data-event", eventId);  
            $('#cancelRegistration').modal();  
            $('#cancelRegistration .modal-content p').html($(this).data('singofftext'));              
            $('#cancelRegistration .modal-content h4 span').html($(this).data('event-title'));              
            $('#cancelRegistration').modal('open');  
        }else{
           
            toggleRegistration(eventId);
        }
    });

    $("#cancelRegistration_YesBtn").click(function(e){
       e.preventDefault();
       var href = $('#cancelRegistration').attr("data-href");
       if(typeof href != "undefined" &&  href != ""){
            location.href = $('#cancelRegistration').attr("data-href");
       }else{
            toggleRegistration($('#cancelRegistration').attr("data-event"));
            $('#cancelRegistration').modal('close');
       }
        
    }); 


    $(".profile_cancel_registration").on("click", function(e){
        e.preventDefault();
        $('#cancelRegistration').attr("data-href", $(this).attr("href"));  
        $('#cancelRegistration').modal();  
        $('#cancelRegistration .modal-content h4 span').html($(this).data('event-title'));              
        $('#cancelRegistration .modal-content p').html($(this).data('singofftext'));              
        $('#cancelRegistration').modal('open');
    });

 
})

function toggleRegistration(eventId){
    $.ajax({
        type: "POST",        
        url: Routing.generate('building_event_register', { id: eventId})        
    }).done(function(data){
        if(parseFloat(data.status) == 0){
            $("[data-event='"+eventId+"']").removeClass("checked"); 
        }else{
            $("[data-event='"+eventId+"']").addClass("checked");                
        }
    });
}